import React from 'react';
import { ReactComponent as IconFacebook } from './assets/icons/facebook.svg';
import { ReactComponent as IconTwitter } from './assets/icons/twitter.svg';
import { ReactComponent as IconInstagram } from './assets/icons/instagram.svg';
import { PopupButton } from '@typeform/embed-react'
import "./App.css";


class App extends React.Component {
  render = () => {
    return (
      <div className="card">
        <div className="header">
          <div className="logo">
            <a href=".">Whygo</a>
          </div>
          <div className="social">
            <a href="https://facebook.com" title="Facebook" target="_blank" rel="noopener noreferrer">
              <IconFacebook className="icon" />
            </a>
            <a href="https://twitter.com" title="Twitter" target="_blank" rel="noopener noreferrer">
              <IconTwitter className="icon" />
            </a>
            <a href="https://instagram.com" title="Instagram" target="_blank" rel="noopener noreferrer">
              <IconInstagram className="icon" />
            </a>
          </div>
        </div>
        <div className="content">
          <div className="title-holder">
            <h1>This is Whygo feedback form</h1>
            <p>Partake in this study for the chance to win a Deliveroo voucher</p>
          </div>
          {/*<a href="mailto:user@example.com">*/}
          {/*  <div className="cta">Send us an email</div>*/}
          {/*</a>*/}
        <PopupButton id="sjlUmz3O" className="cta">
          click to open form in popup
        </PopupButton>
        </div>


        <div className="footer">
          <span>© 2024 Whygo</span>
        </div>
      </div>
    );
  }
}

export default App;
